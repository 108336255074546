import React, { useEffect, useRef } from 'react';
import './Insider.css';

const Insider = () => {
    const johnAudioRef = useRef(null);
    const danielAudioRef = useRef(null);
    const johnCircleRef = useRef(null);
    const danielCircleRef = useRef(null);

    useEffect(() => {
        let johnContext, danielContext;
        let johnAnalyser, danielAnalyser;

        const initAudioContext = () => {
            johnContext = new (window.AudioContext || window.webkitAudioContext)();
            danielContext = new (window.AudioContext || window.webkitAudioContext)();

            const johnAudio = johnAudioRef.current;
            const danielAudio = danielAudioRef.current;

            const johnSource = johnContext.createMediaElementSource(johnAudio);
            const danielSource = danielContext.createMediaElementSource(danielAudio);

            johnAnalyser = johnContext.createAnalyser();
            danielAnalyser = danielContext.createAnalyser();

            johnSource.connect(johnAnalyser);
            danielSource.connect(danielAnalyser);

            johnAnalyser.connect(johnContext.destination);
            danielAnalyser.connect(danielContext.destination);

            johnAnalyser.fftSize = 256;
            danielAnalyser.fftSize = 256;

            visualize();
        };

        const visualize = () => {
            const bufferLength = johnAnalyser.frequencyBinCount;
            const johnDataArray = new Uint8Array(bufferLength);
            const danielDataArray = new Uint8Array(bufferLength);

            const renderFrame = () => {
                johnAnalyser.getByteFrequencyData(johnDataArray);
                danielAnalyser.getByteFrequencyData(danielDataArray);

                const johnVolume = johnDataArray.reduce((a, b) => a + b) / johnDataArray.length;
                const danielVolume = danielDataArray.reduce((a, b) => a + b) / danielDataArray.length;

                if (johnCircleRef.current) {
                    johnCircleRef.current.style.transform = `scale(${1 + johnVolume / 100})`;
                }

                if (danielCircleRef.current) {
                    danielCircleRef.current.style.transform = `scale(${1 + danielVolume / 100})`;
                }

                requestAnimationFrame(renderFrame);
            };

            renderFrame();
        };

        const handleUserGesture = () => {
            initAudioContext();
            johnAudioRef.current.play();
            danielAudioRef.current.play();
            window.removeEventListener('click', handleUserGesture);
            window.removeEventListener('touchstart', handleUserGesture);
        };

        window.addEventListener('click', handleUserGesture);
        window.addEventListener('touchstart', handleUserGesture);

        return () => {
            window.removeEventListener('click', handleUserGesture);
            window.removeEventListener('touchstart', handleUserGesture);
        };
    }, []);

    return (
        <>
            <nav className="progressiveBlur">
                <div className="insider-logo">
                    <img src="/insider-logo.png" alt="Insider Logo" />
                </div>
            </nav>
            <div className="insider-container">
                <div className='insider-content-header'>
                    <h1>So what exactly is Tooig and what the heck are we doing?</h1>
                    <p className='insider-content-subheading-1'>July 15th, 2024</p>
                    <p className='insider-content-subheading-2'>Speakers: Oyebamijo, Founder | Daniel, Media Team</p>
                </div>
                <div className='insider-content-body'>
                    <div className='speaker-visualizer'>
                        <div className='circle' ref={johnCircleRef}>J</div>
                        <div className='circle' ref={danielCircleRef}>D</div>
                    </div>
                    <audio ref={johnAudioRef} src="/hello.mp3" autoPlay controls style={{ display: 'none' }} />
                    <audio ref={danielAudioRef} src="/hello-2.mp3" autoPlay controls style={{ display: 'none' }} />
                </div>
            </div>
            <footer className="footer">
                <p>Tooig Insider is a registered trademark of Tooig Inc. All rights reserved.</p>
                <p>Disclaimer: Content and script used in this podcast are completely generated by Tooig and the voices are the output of several generative AI models.</p>
            </footer>
        </>
    );
}

export default Insider;
