import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../index.css';
import Insider from './insider/Insider';

function App() {
  const [audioSrc, setAudioSrc] = useState(null);

  return (
    <Router>
      <div className="App">

        <Routes>
          <Route path="/" element={<Insider />} />
        </Routes>

        {/* this is the utility part of the website... it handles analytics and some speed insights from vercel and all */}
        <Analytics />
        <SpeedInsights />
      </div>
    </Router>
  );
}

export default App;